import React from "react";

const Characteristic = (props) => {
  const { Title, Description, Icon } = props;

  return (
    <div className="d-flex mb-3 align-items-center w-100">
      <div className="mr-3 h1 mb-0 text-primary">
        <i className={`${Icon}`}></i>
      </div>
      <div>
        <h3 className="h6 mb-1 font-weight-bold text-blue">{Title}</h3>
        <p className="mb-0 small">{Description}</p>
      </div>
    </div>
  );
};

export default Characteristic;
