import React from "react";
import Data from "./../data/Data";

const Share = () => {
  const { shareLinks } = Data;

  return shareLinks.map((link, i) => (
    <li key={i} className="mr-3">
      <a
        href={link.url}
        className={`${link.bgClassName} icon-social`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <i className={`${link.iconClassName}`}></i>
      </a>
    </li>
  ));
};

export default Share;
