import React from "react";

const Advisory = (props) => {
  return (
    <section className="bg-special py-5 text-white">
      <div className="container">
        <div className="d-md-flex align-items-center w-100 justify-content-between">
          <div>
            <h2 className="font-weight-extrabold mb-1">
              ¡Te asesoramos sin compromiso!
            </h2>
            <p className="mb-0">
              Contáctenos y te ayudamos a despejar todas las dudas que tengas
            </p>
          </div>
          <div>
            <div className="mb-2 mt-4 mt-lg-0">
              <a href="#footer" className="btn btn-light font-weight-extrabold">
                Contáctanos
              </a>
            </div>
            <p className="small mb-0">Lunes a viernes de 8 a.m. a 6 p.m.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Advisory;
