import React, { Fragment } from "react";

const Featured = (props) => {
  const { Title, Description, Icon } = props;

  return (
    <Fragment>
      <div className="mb-2 display-5 text-red">
        <i className={`${Icon}`}></i>
      </div>
      <h3 className="h5 font-weight-bold text-blue">{Title}</h3>
      <p>{Description}</p>
    </Fragment>
  );
};

export default Featured;
